// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Chrome, Safari, Edge, Opera */
input.removeArrows::-webkit-outer-spin-button,
input.removeArrows::-webkit-inner-spin-button,
.removeArrows input::-webkit-outer-spin-button,
.removeArrows input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.removeArrows[type="number"],
.removeArrows input[type="number"] {
  -moz-appearance: textfield;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,gCAAgC;EAChC,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA,gCAAgC;AAChC;;;;EAIE,wBAAwB;EACxB,SAAS;AACX;;AAEA,YAAY;AACZ;;EAEE,0BAA0B;AAC5B","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Inter\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n/* Chrome, Safari, Edge, Opera */\ninput.removeArrows::-webkit-outer-spin-button,\ninput.removeArrows::-webkit-inner-spin-button,\n.removeArrows input::-webkit-outer-spin-button,\n.removeArrows input::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput.removeArrows[type=\"number\"],\n.removeArrows input[type=\"number\"] {\n  -moz-appearance: textfield;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
